<template>
  <div v-if="config" class="footer">
    <div class="container footer-container">
      <div class="footer-content-holder">
        <div
          id="w-node-_02a4587e-f715-d682-8f0a-14721236c0e2-0a144c54"
          class="footer-main-content"
        >
          <div class="footer-brand-grid-content">
            <img
              src="@/assets/images/Logo-Color.svg"
              alt="zweikern Logo"
              class="footer-brand-image"
            />

            <div class="footer-main-description">
              {{ config.footer_text }}
            </div>
            <!-- <a
              data-w-id="02a4587e-f715-d682-8f0a-14721236c0e8"
              href="/pricing-v1"
              class="button grey-button w-button"
              style="
                opacity: 1;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                  rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
              "
              >Join the future</a
            > -->
          </div>
        </div>
        <div
          id="w-node-_02a4587e-f715-d682-8f0a-14721236c0ea-0a144c54"
          class="footer-main-grid"
        >
          <div class="footer-content-grid">
            <div
              class="footer-main-heading"
              style="
                opacity: 1;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                  rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
              "
            >
              {{ $t("default.footer.nav.solutions.title") }}
            </div>

            <div class="footer-links-holder">
              <ul>
                <li v-for="blok in products" :key="blok._uid">
                  <MyLink
                    v-bind="link(blok)"
                    :button="false"
                    class="footer-main-link w-inline-block"
                  >
                    {{ blok.name || blok.link.title }}
                  </MyLink>
                </li>
              </ul>
            </div>
          </div>

          <div class="footer-content-grid">
            <div
              class="footer-main-heading"
              style="
                opacity: 1;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                  rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
              "
            >
              {{ $t("default.footer.nav.services.title") }}
            </div>
            <div class="footer-links-holder">
              <ul>
                <li v-for="blok in footerMenuLinks" :key="blok._uid">
                  <MyLink
                    :link="linkBinding(blok)"
                    class="footer-main-link w-inline-block"
                  >
                    {{ blok.name || blok.link.title }}
                  </MyLink>
                </li>
              </ul>
            </div>
          </div>

          <div class="footer-content-grid">
            <div
              class="footer-main-heading"
              style="
                opacity: 1;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                  rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
              "
            >
              {{ config.footer_title_nav }}
            </div>
            <div class="footer-links-holder">
              <ul>
                <li v-for="blok in footerMenu" :key="blok._uid">
                  <MyLink
                    :link="linkBinding(blok)"
                    class="footer-main-link w-inline-block"
                  >
                    {{ blok.name || blok.link.title }}
                  </MyLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-made-by-section">
        <div
          data-w-id="02a4587e-f715-d682-8f0a-14721236c119"
          class="footer-copyright-text"
          style="
            opacity: 1;
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
              rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            transform-style: preserve-3d;
          "
        ></div>
        <div
          class="footer-social-media-icons"
          style="
            opacity: 1;
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
              rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            transform-style: preserve-3d;
          "
        >
          <a
            href="https://www.linkedin.com/company/zweikern/"
            target="_blank"
            class="footer-social-media-link w-inline-block"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              fill="currentColor"
              viewBox="0 0 174 174"
              app="ikonik"
              class="social-media-icon"
            >
              <path
                d="M144.383 6.36133H31.6128C17.6593 6.36133 6.3252 17.7533 6.3252 31.7964V145.207C6.3252 159.247 17.6597 170.643 31.6128 170.643H144.379C158.343 170.643 169.671 159.247 169.671 145.207V31.793C169.671 17.7533 158.343 6.36167 144.383 6.36167V6.36133ZM47.3016 157.169L18.99 157.53V64.3679L47.3016 64.0139V157.169V157.169ZM33.4399 50.5723C24.8791 50.5723 17.9412 43.5949 17.9412 34.9855C17.9412 26.3907 24.8829 19.4061 33.4399 19.4061C41.9934 19.4061 48.9279 26.3941 48.9279 34.9855C48.9279 43.5949 41.9934 50.5723 33.4399 50.5723V50.5723ZM158.044 157.183L130.588 157.597V108.781C130.588 98.0048 127.924 91.0735 115.132 91.0735C103.875 91.0735 99.1652 93.0986 99.1652 107.995V157.186L70.9629 157.53V64.3675L97.9964 64.0346V75.876L98.0105 79.2248C106.004 71.3269 114.259 65.3621 127.473 65.3621C142.993 65.3621 158.048 71.9219 158.048 93.2787V157.183L158.044 157.183Z"
                app="ikonik"
              ></path></svg
          ></a>
          <a
            href="https://www.xing.com/pages/zweikernkg"
            target="_blank"
            class="footer-social-media-link w-inline-block"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 177 177"
              fill="none"
              app="ikonik"
              class="social-media-icon"
            >
              <defs app="ikonik">
                <clipPath app="ikonik">
                  <rect
                    width="24"
                    height="24"
                    fill="currentColor"
                    app="ikonik"
                    class="rect-9rxz2"
                  ></rect>
                </clipPath>
              </defs>
              <g clip-path="url(#clip0_2601_269)" app="ikonik">
                <path
                  d="M145.214 6.35779H31.7926C17.7495 6.35779 6.36133 17.739 6.36133 31.7818V145.196C6.36133 159.239 17.7495 170.642 31.7926 170.642H145.211C159.25 170.642 170.635 159.239 170.635 145.196V31.7818C170.635 17.7421 159.25 6.35779 145.214 6.35779H145.214ZM25.3142 108.405L47.7541 75.4265L25.367 46.8196L42.8617 33.1304L75.504 74.8564L43.6053 121.032L25.3142 108.405V108.405ZM94.6449 106.097L129.344 153.583L111.474 166.776L67.2172 106.795L71.692 100.288L133.345 10.2096L151.693 22.759L94.6456 106.098L94.6449 106.097Z"
                  fill="currentColor"
                  app="ikonik"
                  class="path-yxteo"
                ></path>
              </g></svg></a
          ><a
            href="https://www.youtube.com/c/Zweikern"
            target="_blank"
            class="footer-social-media-link w-inline-block"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 177 177"
              fill="none"
              app="ikonik"
              class="social-media-icon"
            >
              <path
                d="M106.812 107.736C104.341 107.736 101.785 108.724 100.447 112.119H100.345V92.599H91.8242V148.471H99.754V143.95H99.8566C101.251 147.628 103.924 149.607 106.657 149.745C112.87 149.462 114.091 141.752 114.091 135.047V121.322C114.091 115.092 112.438 107.736 106.813 107.736L106.812 107.736ZM105.58 133.699C105.58 136.237 105.683 140.559 102.957 140.559C100.869 140.63 100.345 136.673 100.345 133.271V123.921C100.345 120.112 100.816 117.008 103.117 117.008C105.58 117.008 105.58 121.107 105.58 123.437V133.699V133.699Z"
                fill="white"
              />
              <path
                d="M74.9378 137.239C74.9378 139.292 73.9785 140.475 72.474 140.559C70.8775 140.697 70.4917 139.073 70.4032 137.239V108.862H61.8682V141.413C61.8682 146.779 64.5515 149.746 69.4757 149.746C72.166 149.746 74.6332 146.648 75.4336 143.608H75.5221V148.475H83.4518V108.863H74.9382V137.239L74.9378 137.239Z"
                fill="white"
              />
              <path
                d="M64.5734 92.6135H33.1064L33.2516 100.869H44.1759L44.0234 147.997H53.4576L53.6136 100.869H64.7148L64.5734 92.6135Z"
                fill="white"
              />
              <path
                d="M132.438 107.736C125.85 107.8 120.976 111.124 120.976 121.323V137.441C120.976 146.499 126.325 149.816 132.438 149.749C139.815 149.611 144.01 144.945 143.575 134.761H135.125C134.959 138.085 135.277 141.834 132.109 141.621C130.233 141.484 129.49 139.643 129.49 137.444V130.51H143.89V119.206C143.894 110.625 138.379 107.666 132.438 107.736ZM135.394 123.079H129.493V120.466C129.493 117.701 130.073 115.163 132.494 115.163C134.686 115.163 135.394 117.776 135.394 119.203V123.079V123.079Z"
                fill="white"
              />
              <path
                d="M145.204 6.36853H31.7926C17.7495 6.36853 6.36133 17.7463 6.36133 31.7964V145.211C6.36133 159.254 17.7495 170.635 31.7926 170.635H145.2C159.25 170.635 170.635 159.254 170.635 145.211V31.7964C170.635 17.7494 159.25 6.36853 145.204 6.36853V6.36853ZM105.035 27.8844H113.549V56.2504C113.666 58.0947 114.048 59.7161 115.641 59.5674C117.139 59.5142 118.112 58.3073 118.112 56.2504V27.8844H126.622V67.4968H118.7V62.6151H118.604C117.79 65.6628 115.319 68.7783 112.646 68.7783C107.726 68.7783 105.035 65.8049 105.035 60.4237V27.8841V27.8844ZM76.7154 40.3346C76.7154 30.1536 81.2607 26.755 87.8522 26.755C95.3533 26.755 98.9997 31.4244 98.9997 40.3346V56.4661C98.5607 65.4437 94.3376 68.778 87.8522 68.778C81.7492 68.778 76.7154 65.5104 76.7154 56.4661V40.3346V40.3346ZM56.4278 14.4504L61.1183 31.7715H61.242L66.0494 14.45H75.4764L65.7766 45.2162V67.4971H56.3604V45.2162L46.8767 14.45H56.4274L56.4278 14.4504ZM153.25 146.301C153.25 155.289 146.036 162.557 137.133 162.557H39.8568C30.9467 162.557 23.7357 155.289 23.7357 146.301V93.1483C23.7357 84.178 30.9467 76.8999 39.8568 76.8999H137.133C146.035 76.8999 153.25 84.1784 153.25 93.1483V146.302V146.301Z"
                fill="white"
              />
              <path
                d="M87.8485 60.6439C89.6116 60.6439 90.3231 59.4332 90.479 57.1782V39.4889C90.479 36.947 89.817 34.8904 87.8489 34.8904C85.8666 34.8904 85.2188 36.9473 85.2188 39.4889V57.1782C85.3923 59.4367 86.0861 60.6439 87.8489 60.6439H87.8485Z"
                fill="white"
              /></svg
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const websiteConfig = useState("config");
const config = ref(websiteConfig.value.data.story.content);

const i18n = useI18n();
const locale = i18n.locale;
const utils = useUtils();

const { $store } = useNuxtApp();
const storyblokLinks = computed(() => $store.getters.getLinks);

const localePath = useLocalePath();

const footerMenu = ref(null);
const footerMenuLinks = ref(null);

const updateLinks = () => {
  footerMenu.value = websiteConfig.value.data.story.content.footer_menu.map(
    (link) => utils.mapLinks(link, storyblokLinks.value, locale.value),
  );
  footerMenuLinks.value =
    websiteConfig.value.data.story.content.footer_menu_links.map((link) =>
      utils.mapLinks(link, storyblokLinks.value, locale.value),
    );
};

updateLinks();

i18n.onLanguageSwitched = async (old, newLocale) => {
  updateLinks();
};

const linkBinding = (blok) => {
  return {
    ...blok.link,
    ...(!utils.isEventLink(blok.link)
      ? {
        url: localePath(`/${blok.full_path}`),
      }
      : null),
  };
};

const _useProducts = await useProducts();
const { show, link } = _useProducts;
const products = await _useProducts.all();
</script>

<style scoped>
.footer-brand-image {
  height: 36px;
  width: auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin-bottom: 10px;
}
.column-second {
  text-align: left;
}
.footer-block {
  padding-right: 40px;
  text-align: left;
}
.footer-heading.white {
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 30px;
}
.footer-heading {
  font-family:
    Open Sans,
    sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 20px;
  margin-top: 10px;
  text-transform: uppercase;
}
.footer-sub-link {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.07);
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 0;
  text-align: left;
  text-decoration: none;
  transition: color 0.1s;
  width: 100%;
}
</style>

<script setup>
const { $i18n, $router } = useNuxtApp();
const storyblokApi = useStoryblokApi();
const storyblokApiOptions = useStoryblokApiOptions();
const utils = useUtils();
const { data, fetchConfig } = useConfig();
const websiteConfig = useState("config");
const darkMode = useDarkLightSwitch();
const { locale, locales } = useI18n();
const header = ref(null);
const headerMenu = ref(null);
const toggleFlags = ref(false);
const localePath = useLocalePath();
const { showSchedulePopup } = useSchedule();
const { findLinksWithId } = useUtils();

const { $store } = useNuxtApp();
const storyblokLinks = computed(() => $store.getters.getLinks);

const visibleSubmenu = ref(false);
const updateMenu = (locale) => {
  const { data } = websiteConfig.value;
  headerMenu.value = data.story.content.header_menu;
  headerMenu.value = headerMenu.value.map((link) =>
    utils.mapLinks(link, storyblokLinks.value, locale),
  );
};

const availableLocales = computed(() => {
  return locales.value
    .filter((i) => i.code !== locale.value)
    .map((locale) => locale.code);
});

const story = computed(() => $store.getters.getStory);

const switchLanguage = async (locale) => {
  toggleFlags.value = false;
  $i18n.setLocale(locale);
  await fetchConfig();
  updateMenu();
};

const switchLocalePath = (locale) => {
  let translation = story.value?.translated_slugs?.find(
    (translation) => translation.lang === locale,
  );
  if (!translation) {
    translation = {
      lang: locale,
      path: story.value?.default_full_slug,
    };
  }

  const path = translation.path;
  return utils.forceTrailingSlash(
    `/${translation.lang !== "de" ? `${translation.lang}/` : ""}${path !== "home" ? path : ""}`,
  );
};

// Detect Browser Language
if ($router.path === "/" && $i18n.getBrowserLocale() !== locale.value) {
  const locale = $i18n.getBrowserLocale();
  switchLanguage(locale);
  $router.push(switchLocalePath(locale));
}
const closeMobileMenu = () => {
  mobileMenu.value.status = 2;
  setTimeout(() => {
    mobileMenu.value.status = 0;
    mobileMenu.value.isActive = mobileMenu.value.status > 0;
  }, 300);
};
const openMobileMenu = () => {
  mobileMenu.value.status = 1;
};
const toggleMobileMenu = () => {
  mobileMenu.value.status++;

  if (mobileMenu.value.status === 2) {
    closeMobileMenu();
  } else if (mobileMenu.value.status > 2) {
    mobileMenu.value.status = 0;
  }

  mobileMenu.value.isActive = mobileMenu.value.status > 0;
};
const mobileMenu = ref({
  status: 0,
  isActive: false,
});

const showMobileMenu = computed(
  () =>
    utils.breakpoint.value.clientWidth &&
    utils.breakpoint.value.clientWidth < 970,
);
watch(
  () => mobileMenu.value.isActive,
  (newValue) => {
    if (newValue) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  },
);
const navWhite = computed(() => $store.getters.navWhite);

const parentStory = computed(() => {
  return story.value?.parent_id ? findLinksWithId(story.value.parent_id) : null;
});

function changeNavBg() {
  const nav = document.querySelector(".nav-bg");
  const scrollTop = window.scrollY;
  const maxScroll = 100;
  const opacity = Math.min((scrollTop / maxScroll) * 0.4, 0.4);
  const borderRadius = Math.min((scrollTop / maxScroll) * 14, 14);
  const maxBorderRadius = 14;
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const navWidth = screenWidth >= 1440 ? 1350 : 1170;

  nav.style.backgroundColor = `rgba(0, 0, 0, ${0.1 + opacity})`;
  if (story.value?.hasSubnav && scrollTop > 100) {
    visibleSubmenu.value = true;
  } else {
    visibleSubmenu.value = false;
  }
  if (scrollTop > 100) {
    nav.style.borderBottomLeftRadius = `${maxBorderRadius - borderRadius}px`;
    nav.style.borderBottomRightRadius = `${maxBorderRadius - borderRadius}px`;
    nav.style.maxWidth = "100%";
  } else {
    nav.style.borderBottomLeftRadius = `${maxBorderRadius}px`;
    nav.style.borderBottomRightRadius = `${maxBorderRadius}px`;
    nav.style.maxWidth = `${navWidth}px`;
  }
}

const salesContactButton = await useSalesContactButton();

function initOnScrollListener() {
  window.addEventListener("scroll", changeNavBg);
}
onMounted(() => {
  changeNavBg();
  initOnScrollListener();
  updateMenu();
});
</script>

<template>
  <div
    v-if="showMobileMenu"
    class="sticky-mainnav mobile-menu"
    :class="{ active: mobileMenu.isActive, close: mobileMenu.status === 2 }"
  >
    <nav
      v-if="headerMenu"
      role="navigation"
      class="flex h-full text-center items-center"
    >
      <ul class="space-y-5">
        <li v-for="blok in headerMenu" :key="blok._uid">
          <NuxtLink
            :to="localePath(`/${blok.full_path}`)"
            class="main-navigation-item"
            @click="closeMobileMenu"
          >
            {{ blok.name }}
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
  <header
    class="nav-main"
    :class="{
      'sticky-mainnav': true,
      'mobile-menu-active': showMobileMenu && mobileMenu.isActive,
      white: navWhite && !mobileMenu.isActive,
    }"
  >
    <div class="nav-rounded-center w-nav">
      <div class="nav-bg-holder">
        <div class="nav-bg"></div>
      </div>
      <div class="container nav-container">
        <div class="nav-menu-parent-rounded-center">
          <NuxtLink :to="localePath('/')" class="brand-nav w-nav-brand md:mr-2">
            <span>zweikern</span>
            <div class="dark-logo in-colors"></div>
            <div class="white-logo"></div>
          </NuxtLink>

          <button
            v-if="showMobileMenu"
            class="menu-click-area mdi"
            :class="{
              'mdi-menu': !mobileMenu.isActive,
              'mdi-close': mobileMenu.isActive,
            }"
            @click.stop="toggleMobileMenu"
          />
          <nav v-else-if="headerMenu" role="navigation" class="nav-menu">
            <v-chip
              v-if="visibleSubmenu"
              class=""
              color="primary"
              variant="outlined"
            >
              {{ parentStory?.name }}
            </v-chip>
            <div v-show="visibleSubmenu" id="sticky-sub-menu"></div>
            <div>
              <ul v-show="!visibleSubmenu" class="flex gap-0">
                <li
                  v-for="blok in headerMenu"
                  :key="blok._uid"
                  class="nav-link-holder"
                >
                  <MyLink
                    :link="{ linktype: 'story', url: blok.full_path }"
                    class="nav-link"
                  >
                    {{ blok.name }}
                    <div class="link-background"></div>
                  </MyLink>
                </li>
              </ul>
            </div>
            <ul class="nav-main-buttons-wrap">
              <li class="nav-link-holder">
                <NuxtLink to="/shop" class="nav-link">
                  <span class="mdi mdi-basket"></span>
                  <div class="link-background"></div>
                </NuxtLink>
              </li>

              <li class="nav-link-holder">
                <a
                  href="https://analytics.zweikern.com/"
                  target="_blank"
                  class="nav-link w-inline-block"
                >
                  <span class="mdi mdi-login"></span>
                  <div class="link-background"></div>
                </a>
              </li>

              <li class="nav-link-holder nav-button-holder">
                <SalesContactButton class="nav-button" no-icon />
              </li>

              <li class="nav-link-holder ml-2">
                <ul
                  v-if="story"
                  class="flex gap-2 self-center items-center m-0 h-full"
                >
                  <img
                    :src="`/lang_icon_${locale}.png`"
                    class="flag cursor-pointer"
                    :alt="`${locale === 'de' ? 'Aktuelle Sprache: Deutsch' : 'Current language: English'}`"
                    @click="toggleFlags = !toggleFlags"
                  />
                  <template v-for="lang in availableLocales" :key="lang">
                    <li v-show="toggleFlags && lang !== locale" class="">
                      <NuxtLink
                        :to="switchLocalePath(lang)"
                        @click.stop="switchLanguage(lang)"
                      >
                        <img
                          :src="`/lang_icon_${lang}.png`"
                          :alt="`${
                            lang === 'de'
                              ? `Zur deutschen Version von ${story.name}`
                              : `To english version of ${story.name}`
                          }`"
                          class="flag"
                        />
                      </NuxtLink>
                    </li>
                  </template>
                </ul>
              </li>
            </ul>
          </nav>

          <div
            v-if="false"
            :class="{
              'theme-light': !darkMode,
              'theme-dark': darkMode,
            }"
            class="bg-themeBackground text-themeText p-5"
          >
            <div @click="darkMode = !darkMode">darkMode</div>
            <!-- <Toggle v-model="darkMode" off-label="Light" on-label="Dark" /> -->
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<!-- <style src="@vueform/toggle/themes/default.css"></style> -->
<style lang="scss" scoped>
.nav-bg {
  background-color: rgba(0, 0, 0, 0.4);
  left: 50%;
  transform: translateX(-50%);
  max-width: calc(1170px + 0px);
  border-radius: 14px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: all 600ms;
}

@media screen and (min-width: 1440px) {
  .nav-bg {
    max-width: calc(1350px + 0px);
  }
}

.brand-nav {
  width: 55px;
  flex: none;
  align-items: center;
  padding-left: 0;
  display: flex;
  overflow: hidden;

  span {
    text-indent: -100px;
  }

  &:hover {
    .dark-logo {
      opacity: 1;
    }
    .white-logo {
      opacity: 0;
    }
  }
}

.dark-logo,
.white-logo {
  transition: all 600ms ease-in-out;
}

.dark-logo {
  margin-top: 0;
  position: absolute;
  transition: all 600ms ease-in-out;

  &.in-colors {
    width: 36px;
    height: 36px;
    max-height: 36px;
    opacity: 0;
    background-image: url("@/assets/images/Logo-Color.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 6px;
    display: block;
  }
}

.white-logo {
  width: 36px;
  height: 36px;
  max-height: 36px;
  opacity: 1;
  object-fit: fill;
  -o-object-fit: fill;
  background-image: url("@/assets/images/zweikern-trans-solo.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.menu-click-area {
  position: relative;
  z-index: 10000;
}
.mobile-menu {
  overflow: hidden;
  align-items: center;
  background-color: transparent;
  color: #f2f2f2;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  transition:
    background-color 400ms cubic-bezier(0.4, 0, 0.2, 1),
    width 400ms ease,
    opacity 400ms linear;
  width: 0vw;
  height: 0vh;
  max-width: 100%;
  opacity: 0;

  a,
  button {
    color: inherit;
    border-bottom: 1px solid transparent;
  }

  a.main-navigation-item {
    &.router-link-active,
    &:hover {
      border-bottom-color: #fff;
    }
  }
}
.active.mobile-menu {
  background-color: #2e373ff1;
  backdrop-filter: blur(5px);
  opacity: 1;
  width: 100vw;
  height: 100vh;
}
.active.close.mobile-menu {
  width: 0vw;
  opacity: 0;
}

.flag {
  height: 22px;
  width: 22px;
  display: inline-block;
  border-radius: 100%;
  filter: grayscale(20%);
  opacity: 0.8;
  transition: all 200ms linear;

  &:hover {
    opacity: 1;
    filter: grayscale(0%);
  }
}
ul {
  margin: 0;
}
.main-nav {
  background-color: #0a0c0d25;
  backdrop-filter: blur(5px);
  transition:
    all 400ms ease,
    height 600ms ease;
  height: 50px;

  border-bottom: 1px solid transparent;

  .inside {
    position: relative;
    z-index: 10000;
  }

  .dark-logo.in-colors {
    transition: all 200ms linear;
    opacity: 1;
  }

  a,
  button {
    color: #fff;

    &.main-navigation-item {
      border-bottom: 1px solid transparent;
      margin-top: 1px;
    }

    &.main-navigation-item {
      &.router-link-active,
      &:hover {
        border-bottom-color: #a2027b;
      }
    }
  }

  &:not(.white) {
    background-color: #0a0c0d25;
    height: 50px;

    .dark-logo.in-colors {
      opacity: 0;
    }

    a,
    button {
      color: #fff;
    }

    a.main-navigation-item {
      &.router-link-active,
      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 0;
}

.link-background {
  opacity: 0;
  margin: 2px;
  transition: all 200ms linear;
}
a,
button {
  &:hover {
    .link-background {
      opacity: 1;
      margin: 0px;
    }
  }
}
</style>
